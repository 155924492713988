body {
  margin: 0;
  font-family: Helvetica;
  line-height: 1.5;
  font-weight: 300;
  font-size: 14px;
  color: #262626;
  background-color: #f9f9f9;
}

p {
  margin: 0;
}

input, select, button {
  outline: none;
}